var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "Collapse",
        {
          staticClass: "abnormalInformationDiv",
          model: {
            value: _vm.abnormalInfoValue,
            callback: function ($$v) {
              _vm.abnormalInfoValue = $$v
            },
            expression: "abnormalInfoValue",
          },
        },
        [
          _c(
            "Panel",
            {
              attrs: { name: "2" },
              scopedSlots: _vm._u([
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("Input", {
                        staticClass: "co",
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5, maxRows: 10 },
                          placeholder: "",
                          readonly: "",
                        },
                        model: {
                          value: _vm.abnormalInfo,
                          callback: function ($$v) {
                            _vm.abnormalInfo = $$v
                          },
                          expression: "abnormalInfo",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [_vm._v(" 异常结果汇总 ")]
          ),
        ],
        1
      ),
      _vm.physicalType == "健康体检"
        ? _c(
            "Card",
            { staticClass: "positiveRule" },
            [
              _c(
                "Row",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c(
                    "Button",
                    { attrs: { type: "primary" }, on: { click: _vm.add } },
                    [_vm._v("添加结果")]
                  ),
                  _c(
                    "Button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: { type: "error" },
                      on: { click: _vm.removes },
                    },
                    [_vm._v("删除结果")]
                  ),
                  _c(
                    "Button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.splice },
                    },
                    [_vm._v("拆分结果")]
                  ),
                  _c(
                    "Button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.PositivResult },
                    },
                    [_vm._v("阳性结果")]
                  ),
                  _c(
                    "Button",
                    {
                      staticStyle: { "margin-left": "30px" },
                      attrs: { type: "primary" },
                      on: { click: _vm.resultSummary },
                    },
                    [_vm._v("异常结果")]
                  ),
                ],
                1
              ),
              _c("Table", {
                ref: "table",
                staticStyle: { width: "100%" },
                attrs: {
                  loading: _vm.tableLoading,
                  border: "",
                  columns: _vm.positiveColumns,
                  sortable: "custom",
                  data: _vm.positiveResultData,
                  "max-height": 775,
                },
                on: {
                  "on-selection-change": _vm.onSelects,
                  "on-select": _vm.onSelect,
                  "on-row-click": _vm.rowClick,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "action",
                      fn: function ({ row, index }) {
                        return [
                          _c("Icon", {
                            attrs: { type: "md-arrow-up", size: "15" },
                            on: {
                              click: function ($event) {
                                return _vm.arrowUp(index)
                              },
                            },
                          }),
                          _c("Divider", { attrs: { type: "vertical" } }),
                          _c("Icon", {
                            attrs: { type: "md-arrow-down", size: "15" },
                            on: {
                              click: function ($event) {
                                return _vm.arrowDown(index)
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  72913699
                ),
              }),
              _c(
                "Row",
                { staticStyle: { "margin-top": "10px" } },
                [
                  _c(
                    "Form",
                    {
                      staticStyle: { display: "flex" },
                      attrs: { labelWidth: 120 },
                    },
                    [
                      _c(
                        "FormItem",
                        { attrs: { label: "总检医生:" } },
                        [
                          _c(
                            "Select",
                            {
                              staticClass: "input",
                              attrs: {
                                filterable: true,
                                "label-in-value": "",
                                transfer: "",
                              },
                              on: { "on-select": _vm.doctorSelectChange },
                              model: {
                                value: _vm.doctorId,
                                callback: function ($$v) {
                                  _vm.doctorId = $$v
                                },
                                expression: "doctorId",
                              },
                            },
                            _vm._l(_vm.userArray, function (itemD, index) {
                              return _c(
                                "Option",
                                {
                                  key: index,
                                  attrs: {
                                    value: itemD.id,
                                    label: itemD.nickname,
                                  },
                                },
                                [_vm._v(" " + _vm._s(itemD.nickname) + " ")]
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "FormItem",
                        { attrs: { label: "总检日期：" } },
                        [
                          _c("DatePicker", {
                            staticStyle: { width: "175px" },
                            attrs: {
                              type: "datetime",
                              transfer: "",
                              placeholder: "请选择总检日期",
                            },
                            model: {
                              value: _vm.inspectForm.inspectionDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.inspectForm, "inspectionDate", $$v)
                              },
                              expression: "inspectForm.inspectionDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.physicalType == "从业体检"
        ? _c(
            "Form",
            { staticStyle: { display: "flex" }, attrs: { labelWidth: 120 } },
            [
              _c(
                "FormItem",
                { attrs: { label: "总检医生:" } },
                [
                  _c(
                    "Select",
                    {
                      staticClass: "input",
                      attrs: {
                        filterable: true,
                        "label-in-value": "",
                        transfer: "",
                      },
                      on: { "on-select": _vm.doctorSelectChange },
                      model: {
                        value: _vm.doctorId,
                        callback: function ($$v) {
                          _vm.doctorId = $$v
                        },
                        expression: "doctorId",
                      },
                    },
                    _vm._l(_vm.userArray, function (itemD, index) {
                      return _c(
                        "Option",
                        {
                          key: index,
                          attrs: { value: itemD.id, label: itemD.nickname },
                        },
                        [_vm._v(" " + _vm._s(itemD.nickname) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "FormItem",
                { attrs: { label: "总检日期：" } },
                [
                  _c("DatePicker", {
                    staticStyle: { width: "175px" },
                    attrs: {
                      type: "datetime",
                      transfer: "",
                      placeholder: "请选择总检日期",
                    },
                    model: {
                      value: _vm.inspectForm.inspectionDate,
                      callback: function ($$v) {
                        _vm.$set(_vm.inspectForm, "inspectionDate", $$v)
                      },
                      expression: "inspectForm.inspectionDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "Button",
                {
                  staticStyle: { "margin-left": "30px" },
                  attrs: { type: "primary" },
                  on: { click: _vm.resultSummary },
                },
                [_vm._v("异常结果")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.physicalType == "从业体检"
        ? _c(
            "Card",
            { staticClass: "positiveRule" },
            [
              _c(
                "Row",
                { staticStyle: { "margin-bottom": "10px" } },
                [
                  _c("Col", { attrs: { span: "24" } }, [
                    _c("div", { staticClass: "txt" }, [
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "red",
                            "font-size": "16px",
                            position: "relative",
                            top: "4px",
                          },
                        },
                        [_vm._v("*")]
                      ),
                      _vm._v("疾病诊断"),
                    ]),
                  ]),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("1.细菌性痢疾"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseOne,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseOne",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseOne",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("2.伤寒和副伤寒"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseTwo,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseTwo",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseTwo",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("3.病毒性肝炎（甲型、戊型）"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseThree,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseThree",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseThree",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("4.活动性肺结核"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseFour,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseFour",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseFour",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("5.化脓性或渗出性皮肤病"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseFive,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseFive",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseFive",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("6.手癣、指甲癣"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseSix,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseSix",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseSix",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [_vm._v("7.手部湿疹")]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseSeven,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseSeven",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseSeven",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "Col",
                    { attrs: { span: "6" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _vm._v("8.手部的银屑病或者鳞屑"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          model: {
                            value: _vm.diseaseDiagnosisForm.isDiseaseEight,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.diseaseDiagnosisForm,
                                "isDiseaseEight",
                                $$v
                              )
                            },
                            expression: "diseaseDiagnosisForm.isDiseaseEight",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: 0 } }, [_vm._v("无")]),
                          _c("Radio", { attrs: { label: 1 } }, [_vm._v("有")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "Row",
                { staticStyle: { padding: "0 16px 0 0" } },
                [
                  _c(
                    "Col",
                    { attrs: { span: "3" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "font-size": "16px",
                              position: "relative",
                              top: "4px",
                            },
                          },
                          [_vm._v("*")]
                        ),
                        _vm._v("健康证条件"),
                      ]),
                      _c(
                        "RadioGroup",
                        {
                          attrs: {
                            type: "button",
                            size: "small",
                            "button-style": "solid",
                          },
                          on: { "on-change": _vm.pick },
                          model: {
                            value: _vm.inspectForm.healthCertificateConditions,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.inspectForm,
                                "healthCertificateConditions",
                                $$v
                              )
                            },
                            expression:
                              "inspectForm.healthCertificateConditions",
                          },
                        },
                        [
                          _c("Radio", { attrs: { label: "合格" } }),
                          _c("Radio", { attrs: { label: "不合格" } }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.inspectForm &&
                  _vm.inspectForm.healthCertificateConditions &&
                  _vm.inspectForm.healthCertificateConditions == "不合格"
                    ? _c(
                        "Col",
                        { attrs: { span: "10" } },
                        [
                          _c("div", { staticClass: "txt" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  color: "red",
                                  "font-size": "16px",
                                  position: "relative",
                                  top: "4px",
                                },
                              },
                              [_vm._v("*")]
                            ),
                            _vm._v("异常描述"),
                          ]),
                          _c("Input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 5, maxRows: 10 },
                            },
                            model: {
                              value: _vm.inspectForm.handleOpinion,
                              callback: function ($$v) {
                                _vm.$set(_vm.inspectForm, "handleOpinion", $$v)
                              },
                              expression: "inspectForm.handleOpinion",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("Col", { attrs: { span: "1" } }),
                  _c(
                    "Col",
                    { attrs: { span: "10" } },
                    [
                      _c("div", { staticClass: "txt" }, [
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "red",
                              "font-size": "16px",
                              position: "relative",
                              top: "4px",
                            },
                          },
                          [_vm._v("*")]
                        ),
                        _vm._v("主检建议"),
                      ]),
                      _c("Input", {
                        attrs: {
                          type: "textarea",
                          autosize: { minRows: 5, maxRows: 10 },
                        },
                        model: {
                          value: _vm.inspectForm.conclusion,
                          callback: function ($$v) {
                            _vm.$set(_vm.inspectForm, "conclusion", $$v)
                          },
                          expression: "inspectForm.conclusion",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "Modal",
        {
          staticClass: "tPositiveResultModal",
          staticStyle: { "max-height": "80%" },
          attrs: { title: "阳性结果", "footer-hide": true, width: "90%" },
          model: {
            value: _vm.tPositiveResulShow,
            callback: function ($$v) {
              _vm.tPositiveResulShow = $$v
            },
            expression: "tPositiveResulShow",
          },
        },
        [
          _c("tPositiveResult", {
            attrs: {
              tPositiveResulShow: _vm.tPositiveResulShow,
              PositiveResultId: _vm.PositiveResultId,
            },
          }),
        ],
        1
      ),
      _c("positiveResult", {
        attrs: { personId: _vm.personId },
        model: {
          value: _vm.modalResult,
          callback: function ($$v) {
            _vm.modalResult = $$v
          },
          expression: "modalResult",
        },
      }),
      _c("drawer", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          type: _vm.type,
          inspectForm: _vm.inspectForm,
          inspectType:
            _vm.personInfo.physical_type || _vm.personInfo.physicalType,
          personInfo: _vm.personInfo,
          hazardFactors:
            _vm.personInfo.hazard_factors || _vm.personInfo.hazardFactors,
          hazardFactorsText:
            _vm.personInfo.hazard_factors_text ||
            _vm.personInfo.hazardFactorsText,
          isMultiple: _vm.isMultiple,
        },
        on: { getSelectedWords: _vm.getSelectedWords },
        model: {
          value: _vm.wordChooseShow,
          callback: function ($$v) {
            _vm.wordChooseShow = $$v
          },
          expression: "wordChooseShow",
        },
      }),
      _c("drawer-science-explain", {
        attrs: {
          width: "400",
          modalTitle: _vm.title,
          positiveName: _vm.positiveName,
          inspectType:
            _vm.personInfo.physical_type || _vm.personInfo.physicalType,
          personInfo: _vm.personInfo,
        },
        on: { getSelectedWords: _vm.getSelectedWords },
        model: {
          value: _vm.scienceExplainChooseShow,
          callback: function ($$v) {
            _vm.scienceExplainChooseShow = $$v
          },
          expression: "scienceExplainChooseShow",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }